
.a-cst{
    color: var(--text-color-main);
}

a{
    color: var(--text-color-main) !important;
}

a:link{
    text-decoration: none;
}

  div{
    font-family: 'Light';   
    font-size: 0.75rem;
  }

  .title-section{
    font-weight: bold;
    font-size: 1rem;
  }

  h2{
    text-align: left !important;
  }

  @media screen and (max-width: 1380px)
  {
      div{
          font-size: 0.7rem;
        }
      
      h2{
          font-size: 1.3rem;
        }
      
  }


  @media screen and (max-width: 992px)
{
    div{
        font-size: 0.65rem;
      }
    
    h2{
        font-size: 1.3rem;
      }
    
}


/* classes for modal forms */



.alert-box{
    margin-top: 2rem;
    background-color: transparent;  
}

.alert-box-error{
    color: red;
}

.button-row{
    margin-top: 0.3rem;
    margin-bottom : 0.3rem;
}


.background-contact-box{
    background-color: white;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding-bottom : 5vh;
    margin-left: 15vw;
    margin-right : 15vw;
    min-height: 90vh;
    width: 70vw;
}

.contact-form-border{
    border-style: solid;
    border-color: rgb(70,70,70);
    border-width: 2px;
    margin-left:10%;
    margin-right:10%;
    padding-top: 2%;
    padding-bottom: 10%;
}

.contact-row{
    margin-top: 1rem;
    margin-bottom : 1rem;
}

.contact-top-bar{
    min-width: 100%;
    min-height: 50px;
}

.privacy-paragraph-title{
    padding-left: 1rem;
    text-align: left;
}

.privacy-paragraph-content{
    text-align: justify;
    word-wrap: break-word;
    hyphens: auto;
    padding-right: 1rem;
}

@media screen and (max-width: 992px)
{
    .background-contact-box{
        margin-left: 5vw;
        margin-right : 5vw;
        width: 90vw;
    }

    .privacy-paragraph-title{
        padding-left: 0;
        text-align: center;
    }

    .privacy-paragraph-content{
        padding-top: 0.2rem;
        padding-bottom: 1rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
}
/* END - classes for modal forms */

  
.alignL
{
    margin-left: 0px;
    margin-right: auto;
    display: flex;
}

.alignR
{
    position : absolute;
    right: 0;
}

.alignT
{
    padding-top: 0px;
    margin-bottom: auto;
    display: flex;
}

.background-unavailable{
    position : fixed;
    top : 0;
    left : 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50,50,50,0.5);
    z-index: 2;
}


.centerH
{
    margin:auto;
}

.centerV{
    margin: 0;
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
}

.hidden{
    display: none;
}

input.checkbox-cst{
    min-height: 16px;
    min-width: 16px;
}

input.checkbox-cst:hover{
    transform: scale(1.2);
}


.button-cst:hover{
    transform: scale(1.2);
}

.close-button{
    position : relative;
    float: right;
    height: 80%;
    min-height: 50px;
    font-size: 2rem;
    background-color: transparent;
    border: none;
    z-index: 101;
}

.font-small{
    font-size: 0.7rem;
}

.font-verysmall{
    font-size: 0.55rem;
}

.form-control{
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
    font-size: inherit !important;
}

.input-group{
    margin: auto;
    max-width: 10rem;
    
}

.justify-text{
  text-align: justify;
  word-wrap: break-word;
  hyphens: auto;
}


.main-grid{
    column-gap: 2%;
    row-gap: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
}

.main-grid-preview{
    column-gap: 2%;
    row-gap: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    
}

.marginL-s
{
    margin-left: 20px;
}

.marginR-s
{
    margin-right: 20px;
}

.marginT-s
{
    margin-top: 20px;
}

.marginT-m
{
    margin-top: 40px;
}

.marginT-l
{
    margin-top: 80px;
}

.marginL-l
{
    margin-left: 3vw;
}

.marginR-l
{
    margin-right: 3vw;
}


.paddingL-s
{
    padding-left: 20px;
}

.paddingR-s
{
    padding-right: 20px;
}

.paddingB-s
{
    padding-bottom: 20px;
}

.paddingT-s
{
    padding-top: 20px;
}

.paddingT-m
{
    padding-top: 40px;
}

.paddingT-l
{
    padding-top: 80px;
}

.paddingB-m
{
    padding-bottom: 40px;
}

.paddingB-l
{
    padding-bottom: 80px;
}

.page-background{
    margin-top: 1.5rem;
    margin-left: 10rem;
    margin-right : 10rem;
    width: auto;
}

.paragraph-padding{

}
.react-datetime-picker__wrapper{
    border-style: none !important;
    margin: 0;
}
.datepicker-wrapper-cst{
    text-align:center;
    border-style: none;
}

.datepicker-cst{
    text-align: center;
    max-width: 150px;
}
.text-align-left{
    text-align: left;
}
.text-blur{
    text-shadow: 0px 0px 2px black;
}

.title-footer
{
    margin-bottom : 1em;
    font-weight: bold;
    color: rgb(100,100,100);
}

.transparent{
    background-color: transparent;
}

.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.visible-if-big-screen
{
    height: auto;
    visibility: visible;
}

@media screen and (max-width: 1380px)
{
    .main-grid{
        column-gap: 3%;
        row-gap: 2rem;
        grid-template-columns: 32% 32% 32%;
    }

    .main-grid-preview{
        column-gap: 3%;
        row-gap: 2rem;
        grid-template-columns: 32% 32% 32%;
    }

    .page-background{
        margin-left: 5rem;
        margin-right : 5rem;
    }
}

@media screen and (max-width: 992px)
{
    .main-grid{
        column-gap: 2%;
        row-gap: 1rem;
        grid-template-columns: 49% 49%
    }

    .main-grid-preview{
        column-gap: 2%;
        row-gap: 1rem;
        grid-template-columns: 49% 49%

    }

    .page-background{
        margin-left: 1rem;
        margin-right : 1rem;
    }

    .paragraph-padding{
        margin-left: 2vw;
        margin-right: 4vw;
    }

    .visible-if-big-screen
    {
        height: 0;
        visibility: hidden;
    }
}

@keyframes text-in-animation {
    0% {
        bottom: 0.2em;
        opacity: 1;
    }
    
    50% {
        bottom: -0.2em;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes box-in-animation{
    0% {
        transform: scale(0);
        opacity: 1;
      }
    80% {
        transform: scale(1.1);
        opacity: 1;
      }
    100%{
        transform: scale(1);
        opacity: 1;
    }
}