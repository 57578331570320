.controls-right{
    float: right;
}

.confirm-order-box{
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 60% auto;
}

.confirm-order-info-box{

    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-std);
    padding : 3rem;
    margin-right : 1.5rem;
}

.confirm-order-price-box{

    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-std);
    padding : 3rem;
    margin-left : 1.5rem;
    max-height: 300px;
}

.card-logo{
    max-height:25px;
    margin : 1rem;
}


table{
    width: 100%;
}

.table-small{
    font-size: 0.7rem;
}

.table-desc{
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: left;
}

.table-price{
    max-width: 200px;
    text-align: center;
}

.table-row{
    border-bottom-width: 1px;
    border-bottom-color: var(--border-std);
    border-bottom-style: solid;
}

@media screen and (max-width: 992px)
{
    .confirm-order-box{
        margin-top: 5rem;
        display: inherit;
    }

    .confirm-order-info-box{

        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--border-std);
        padding : 3rem;
        margin-right : 0;
    }
    
    .confirm-order-price-box{
    
        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--border-std);
        padding : 1.5rem;
        margin-top: 3rem;
        margin-left : 0;
        max-height: 1000px;
    }

    .card-logo{
        max-height:20px;
    }
    
}