
.shopping-icon-container-fixed{
    display: none;
}

.shopping-icon-container{
    position : relative;
    background-color: transparent;
}

.shopping-icon{
    font-size: 1.5rem;   
}

.shopping-icon-badge{
    position : absolute;
    right: 0;
    width : 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgba(255, 80, 39);
    color: white;
    font-size: 0.7rem;
}

@media screen and (max-width: 992px)
{
    .shopping-icon-container-fixed{
        padding-top: 0.4rem;
        display: initial;
        position : fixed;
        top : 20vw;
        right: 5vw;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}