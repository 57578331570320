
.preview-container{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding : 0rem;
    width: 40%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2rem;
  }

.preview-content-box{
    border-color: white;
    border-width: 1px;
    border-style: solid;
    align-items: center;
    margin : 0;
    height: 100%;
}  

.preview-content{
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  

.preview-image{
    margin: 0;
    width: 100%;
    height: 100vw;
    max-height: 250px;
    background-position: center;
    background-size: cover;
    padding: 2rem;
}

.preview-title{
    max-width: 100%;
    font-size: 1.5rem;
    color: white;
  }


  @media screen and (max-width: 992px)
{
    .preview-container{
        width: 96%;
        margin-left: 2%;
        margin-right: 2%;
      }
}
