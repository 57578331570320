
.article-alert-box{
    margin-top: 2rem;
    text-align: center;
}

.article-box{
    text-align: left;
}

.article-main{
    display: grid;
    grid-template-columns: 60% auto;
    width: 100%;
}

.article-main-image{
    border-radius: 5px;
    width : 100%;
    max-width: 800px;
    max-height: 520px;
    object-fit:cover;
}

.article-main-image-box{
    width : 100%;
    max-width: 800px;
}

.article-paragraph-title{
    margin-top: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
}

.article-time-table-item{
    display: grid;
    grid-template-columns: 50px auto;
}

.article-text{
    width : 100%;
    text-align: justify;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 800px;
}

.article-price-box{
    max-height: 520px;
    margin-left: 5rem;
    padding : 2rem;
    border-radius: 5px;
    border-color: rgb(100,100,100);
    border-width: 1px;
    border-style: solid;
}

@media screen and (max-width: 1380px)
{
}


@media screen and (max-width: 992px)
{
    .article-main{
        display: block;
    }
    .article-price-box{
        max-height: 1000px;
        margin-left: 0;
        margin-top: 2rem;
        padding: 1rem;
    }
}
