
 .paragraph-preview-box{
    width: 100%;
    height: 100%;
    border-color: white;
    border-width: 1px;
    border-style: solid;
    align-items: center;
    margin: 0;
    padding: 0.5rem;
  }

  .paragraph-container-animation{
      animation-name: box-in-animation;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      background-color: white;
  }

 .paragraph-preview-container{
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding : 0rem;

}
.paragraph-preview-container:hover{
  cursor: pointer;
} 

.paragraph-preview-image{
  margin: 0;
  width: 100%;
  height: 100vw;
  max-height: 250px;
  background-position: center;
  background-size: cover;
}
  
.paragraph-preview-content{
  position: relative;
  background-color: transparent;
  padding: 0.5rem;
  height: 100%;
}

.paragraph-preview-discovermore{
  background-color: transparent;
}

.paragraph-preview-price-container{
  position: absolute;
  bottom : 0;
  margin-bottom: 0.5rem;
}

.paragraph-preview-price{
  background-color: transparent;
  margin-bottom: 0.2rem;
} 
.paragraph-preview-title{
  max-width: 100%;
  font-size: 1rem;
}

.paragraph-background-open{
  background-color: rgba(50,50,50,0.5) !important;
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 110vh;
  top : 0;
  left: 0;
  margin: 0;
  overflow: auto;
}
  

@media screen and (max-width: 1376px)
{
}

  @media screen and (max-width: 992px)
{

.paragraph-preview-image{
  max-height: 140px !important;
}

.paragraph-preview-title{
  max-width: 100%;
  font-size: 0.65rem;
}
.paragraph-preview-price{
  font-size: 0.5rem;
}

  .paragraph-preview-container{

  } 

  .paragraph-preview-container-slide-f{
    display: initial;
    margin-left: 0.5rem;
    margin-right: 0.5rem; 
} 

     
}
