
.background-style{
  padding-top: 38vh;
    width: 100%;
    height: 90vh;
    background-image: url(../../public/assets/images/mainbkg.jpg);
    background-size: cover ;
    background-position: center;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.collage-picture-1{
  position: absolute;
  right : 0;
  margin-top: 5%;
  margin-right: 5%;
  transform: rotate(20deg);
  width: 20%;
}

.collage-picture-2{
  position: absolute;
  right: 0;
  margin-top: 25%;
  margin-right: 5%;
  transform: rotate(-10deg);
  width: 15%;
}

.background-services{
  width: 100%;
}

.footer-bottom{

}

.logo-style{
    max-width: 100px;
}

.text-white{
  color: white;
}

.title-container{
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.title-main {
  font-family: 'Gloria';
    font-size: 3rem;
    text-align: center;
    color: var(--text-color-title);
    background-color: transparent;
    text-shadow: 0 0 2px black;
    animation-name: text-in-animation;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }
  
.title-main span span {
    position: relative;
    animation: text-in-animation 0.2s forwards;
    bottom: -1em;
    opacity: 0;
}

.title-main-sub{
  font-family: 'Gloria';
  opacity: 0;
  font-size: 1.3rem;
  text-align: center !important;
  animation-name: box-in-animation;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  margin-top: 1.5rem;
}

.title-page {
    margin: 1rem;
    margin-left: 0;
    margin-right: 0;
    padding-top: 1.5rem;
    text-align: center;
    color: black;
    animation-name: text-in-animation;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }
  
.title-page span span {
    position: relative;
    animation: text-in-animation 0.2s forwards;
    bottom: -1em;
    opacity: 0;
}


@media screen and (max-width: 992px)
{
  .background-style{
    padding-top: 15vh;
  }
  .title-page{
    font-size: 1.3rem;
  } 
  .footer-bottom{
    margin-bottom: 3em;
  }
  .background-style{
    height: 35vh;
  }
  .title-main{

    font-size: 1.8rem;
  }
  .title-main-sub{
    margin-left: 10%;
    margin-right: 10%;
    font-size: 0.85rem;
    margin-top: 1rem;
  }
}