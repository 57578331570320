
.cart-icon-full{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgba(255, 80, 39);
}
.cart-icon-full-fixed{
    font-size: 1.2rem;
    display: none;
}

.nav-border{
    border-bottom-width: 1px;
    border-bottom-color: rgb(100,100,100);
    border-bottom-style: solid;
}

.nav-container
{
    padding-top: 80px;
}

.nav-fore-color
{
    background-color: rgba(255, 80, 39, 0.8) !important;
}

.nav-item-cst{
    margin-left: 2rem;
    margin-right: 2rem;
}

.nav-item-font-color
{
    color:rgb(100,100,100) !important;
}

.nav-sticky
{
    position: sticky !important;
    top: 0;
}

.logo-social{
    max-width: 30px;
}

.title-header{
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.75rem;
    font-family: 'Cursive';
}

.title-links{
    position : relative;
    right: 0;
    margin-right : 2rem;
    margin-top: auto;
    margin-bottom: auto;
}

@media screen and (max-width: 992px)
{
    .cart-icon-full-fixed{
        position : fixed;
        top : 200px;
        left: 200px;
        z-index: 1000;
    }
    .nav-item-cst{
        width: auto;
        text-align: right;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: var(--border-light);
        margin-top : 0.5rem;
        margin-bottom : 0.5rem;
    }

    .nav-container
    {
        padding-top: 0;
    }

    .title-links{
        margin-right : 0;
    }
}

@media not all and (min-resolution:.001dpcm)
{
    .nav-sticky{
        position: -webkit-sticky !important;
    }
}